"use client";
import Image from "next/image";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter } from "next/navigation";
import { setCookie } from "nookies";
import toast from "react-hot-toast";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import supabase from "./api/supabase";

const formSchema = z.object({
  email: z.string().email({
    message: "Insira um email válido.",
  }),
  password: z.string().min(6, {
    message: "Insira uma senha válida",
  }),
  captcha: z.string().nonempty({
    message: "Por favor, complete o captcha.",
  }),
});

export default function Home() {
  const router = useRouter();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      captcha: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    toast
      .promise(
        supabase.auth
          .signInWithPassword({
            email: values.email,
            password: values.password,
          })
          .then(async ({ error, data }) => {
            if (error) throw error;

            const refToken = data.session?.refresh_token || "";
            const accessToken = data.session?.access_token || "";
            const userEmail = data.user?.email || "";

            setCookie(undefined, "email", userEmail, {
              maxAge: 60 * 60 * 24 * 30,
              path: "/",
            });

            setCookie(undefined, "accessToken", accessToken, {
              maxAge: 60 * 60 * 1,
              path: "/",
            });

            setCookie(undefined, "refreshToken", refToken, {
              maxAge: 60 * 60 * 24 * 30,
              path: "/",
            });

            console.log("Login bem-sucedido:", data);
            router.push("/home");
          }),
        {
          loading: "Fazendo login...",
          success: <b>Login bem-sucedido!</b>,
          error: <b>Erro ao fazer login!</b>,
        }
      )
      .catch((error) => {
        console.error("Erro ao fazer login:", error);
      });
  }

  return (
    <div className="flex flex-col lg:flex-row h-screen">
  <div className="bg-customBlue lg:flex w-full lg:w-6/12 flex-col items-center lg:items-start justify-center h-36 p-6 md:h-40 md:rounded-b-3xl lg:rounded-none   lg:h-screen relative">
    <div className="md:flex flex-col items-center  justify-center lg:items-start  flex-grow -mt-2   ">
      <div className="flex flex-col gap-7 pl-10">
        <Image className="hidden lg:block" src={"/LogoDsk.svg"} alt="" width={228} height={228} />
        <h1 className="text-white text-2xl  max-w-96 md:hidden lg:block hidden">Sua plataforma de <span className="font-semibold">monitoramento</span> pós alta hospitalar.</h1>
      </div>

   
      <Image className="lg:hidden md:w-52 md:mt-5"  src={"/png.png"} alt="" width={180} height={180} />
  
      <p className="text-white md:hidden relative -top-4">Sua plataforma de <span className="font-semibold">monitoramento</span> pós alta hospitalar.</p>
    </div>
    <div className=" w-full flex flex-col justify-center items-center">
    <div className="flex flex-col text-white justify-center items-center gap-2 mt-auto mb-4 lg:mb-8  md:block">
      <h2 className="text-xsx text-center hidden md:hidden lg:block ">Powered By Aicury</h2>
      <p className="underline text-center hidden md:hidden lg:block">https://aicury.com</p>
    </div>
    </div>
  </div>
  
  <div className="w-full lg:w-6/12 h-full flex justify-center md:items-center md:overflow-auto lg:h-screen">
    <div className="bg-white p-8 lg:p-8 rounded-lg max-w-md lg:max-w-xl mx-auto md:border md:border-customBlue lg:min-h-[400px] lg:overflow-hidden">
      <div className="flex flex-col mb-4 lg:mb-6">
      {/* <Image className="md:hidden"  src={"/ebserh.svg"} alt="" width={140} height={140} /> */}
        <h1 className="text-2xl lg:text-3xl font-semibold md:mt-0 text-customBlue">Entrar</h1>
        <p className="max-w-96 text-gray-500">
          Insira seu E-mail abaixo para fazer login em sua conta.
        </p>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-2 lg:space-y-4 flex flex-col w-full mx-auto"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg lg:text-xl text-customBlue font-semibold">
                  E-mail
                </FormLabel>
                <FormControl className="mb-2 lg:mb-3">
                  <Input
                    placeholder="Insira seu email"
                    {...field}
                    className="rounded-lg h-10 lg:h-10 border border-customBorder w-full"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-semibold text-lg lg:text-xl text-customBlue">
                  Senha
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder="Insira sua senha"
                    {...field}
                    className="rounded-lg h-10 lg:h-10 border border-customBorder w-full"
                    type="password"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-end underline text-sm mb-2">
            <span className="text-customBlue">Esqueceu sua senha?</span>
          </div>
          <FormField
            control={form.control}
            name="captcha"
            render={({ field }) => (
              <FormItem className="flex flex-col mb-2">
                <FormControl>
                  <HCaptcha
                    sitekey="9a523764-9504-4c3a-b28e-4b72c5dc0c6c"
                    onVerify={(token) => field.onChange(token)}
                  />
                </FormControl>
                <FormMessage className="text-red-500" />
              </FormItem>
            )}
          />
          <div className="flex flex-col gap-3 mb-2">
            <Button
              type="submit"
              className="bg-customBlue rounded-lg h-10 shadow-md lg:h-10 text-bold text-lg lg:text-xl text-white hover:bg-customHover w-full"
            >
              Entrar
            </Button>
            <Button
              type="button"
              className="bg-transparent border border-customBlue  rounded-lg shadow-md h-10 lg:h-10 text-bold text-lg lg:text-xl  text-customBlue  hover:bg-customHover w-full"
            >
              Entrar com o google
            </Button>
          </div>
          {/* <div className="text-center">
            <span className="text-customBlue">Não tem uma conta? Cadastre-se</span>
          </div> */}
        </form>
      </Form>
    </div>
  </div>
</div>
  );
}
